<template>
  <div class="survey">
    <b-steps size="is-small is-flex is-flex-direction-column pt-3" mobile-mode="compact" :has-navigation="false" v-model="currQuestion">
      <!-- INTRO -->
      <b-step-item
        class="bstep-item is-flex is-flex-direction-column mt-3"
        :class="{hiddenStep:currQuestion != 0}"
        :clickable="currQuestion != options.questions.length + 1">
        <div class="inner-content p-5">
          <h3 class="is-size-5 has-text-weight-bold mb-5">{{ $parent.room.experience.title }}</h3>
          <div class="mb-5">{{ $parent.room.experience.description }}</div>

          <template v-if="blockUntilVerify">
            <div class="box">
              <p class="is-size-5 has-text-weight-bold has-text-centered">{{ $t('exp_survey.youMustVerify') }}</p><br>
              <b-button expanded type="is-primary" size="is-small" @click="OpenVerification()">{{ $t('exp_survey.verify') }}</b-button>
            </div>
          </template>

        </div>
        <div class="next-buttom p-4 has-background-light">
          <b-button
            :disabled="blockUntilVerify"
            type="is-primary"
            :label="$t('exp_survey.letsStart')"
            @click="NextQuestion()"
            expanded/>
        </div>
      </b-step-item>

      <!-- PREGUNTAS -->
      <b-step-item
        v-for="(question, i) in options.questions"
        :key="i" 
        class="bstep-item is-flex is-flex-direction-column mt-3"
        :class="{hiddenStep:currQuestion != i+1,'is-answered': lastAnswered >= i}"
        :clickable="lastAnswered >= i && currQuestion != options.questions.length + 1">
        <div class="inner-content p-5">
          <h3 class="is-size-5 has-text-weight-bold mb-5">{{ question.prompt }}</h3>
          <!-- QUESTION TYPE NUMBER -->
          <template v-if="question.type == 'number'">
            <b-field class="p-0 options number">
              <b-radio-button v-for="j in numberOptions" v-model="answers[i]" :native-value="j" :key="j">
                {{ j }}
              </b-radio-button>
            </b-field>
          </template>
          <!-- QUESTION TYPE EMOJI -->
          <template v-if="question.type == 'emoji'">
            <b-field class="options emoji">
              <b-radio-button
                v-for="j in emojis"
                v-model="answers[i]"
                :native-value="j + 1"
                :key="j">
                <img :src="`/assets/survey/emoji-${j}.svg`">
              </b-radio-button>
            </b-field>
          </template>
          <!-- QUESTION TYPE YESNO -->
          <template v-if="question.type == 'yesno'">
            <b-field class="options yesno">
              <b-radio-button
                v-model="answers[i]"
                native-value="yes">
                {{ $t('exp_survey.yes') }}
              </b-radio-button>
              <b-radio-button
                v-model="answers[i]"
                native-value="no">
                {{ $t('exp_survey.no') }}
              </b-radio-button>
            </b-field>
          </template>
          <!-- QUESTION TYPE TEXT -->
          <template v-if="question.type == 'text'">
            <b-field class="options text">
              <b-input
                type="textarea"
                maxlength="200"
                v-model="answers[i]"></b-input>
            </b-field>
          </template>
        </div>
        <div class="next-buttom is-flex p-4 has-background-light" style="gap: 1.5em">
          <b-button
            :label="$t('exp_survey.previous')"
            @click="PrevQuestion()" 
            type="is-primary is-outlined has-background-white-bis" 
            expanded/>
          <b-button
            :label="$t('exp_survey.next')"
            @click="NextQuestion()"
            type="is-primary"
            :disabled="question.required && (answers[i] == undefined || question.type == 'text' && answers[i].length < minTextLenght)" 
            expanded/>
        </div>
        </b-step-item>

      <!-- CIERRE -->
      <b-step-item
        class="bstep-item is-flex is-flex-direction-column"
        :class="{hiddenStep:currQuestion != options.questions.length+1}">
        <div class="inner-content p-5">
          <template v-if="!sending">
            <h3 class="is-size-4 has-text-weight-bold mb-5"> {{ $t('exp_survey.thankYou') }}</h3>
            <p>{{ options.finalText }}</p>
          </template>
        </div>
        <div class="next-buttom p-4 has-background-light">
          <b-button
            type="is-primary"
            :label="$t('system.backToMenu')"
            expanded
            @click="$router.push('/')"/>
        </div>
      </b-step-item>
    </b-steps>
  </div>
</template>

<script>
import axios from "axios";

import './SurveyController.scss';

export default {
  components: {},
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      emojis: 5,
      numberOptions: 5,
      currQuestion: 0,
      answers: [],
      sending: true,
      minTextLenght: 1,
      lastAnswered: -1,
    };
  },
  computed: {
    blockUntilVerify(){
      return this.options.forceUsersToVerify && !this.$store.state.user.profile.confirmed
    }
  },
  methods: {
    OpenVerification(){
      this.$store.commit("user/ForceVerification");
      this.$store.commit("user/SetAccountState", 3);
    },
    GetAnswers(){
      var out = {}
      this.options.questions.forEach((el, i) => {
        out[el.id] = this.answers[i]
      })
      return out;
    },
    NextQuestion() {
      if(this.currQuestion > this.lastAnswered) this.lastAnswered++
      console.log(this.lastAnswered)
      this.currQuestion++;
      if (this.currQuestion == this.options.questions.length + 1) {
        this.SubmitAnswers();
      }
    },
    PrevQuestion() {
      if (this.currQuestion != 0) this.currQuestion--
    },
    async SubmitAnswers() {
      try {
        console.log(this.GetAnswers())
        this.sending = true
        this.$store.commit("StartLoading")
        const user = this.$store.state.user.profile.id;
        // const answers = JSON.stringify(this.answers);
        const answers = JSON.stringify(this.GetAnswers())
        const survey = this.$parent.room.experience.id;
        const playlist = this.$store.state.setup.playlist.id;

        console.log("Sending survey", answers);
        await axios.post(process.env.VUE_APP_API_BASE + "/api/surveyanswers",
          {
            user,
            playlist,
            survey,
            answers
          },
          {
            headers: {
              Authorization: "JWT " + this.$store.state.user.token,
            },
          }
        )
        this.$store.commit("StopLoading")
        this.sending = false
      } catch (e) {
        console.warn("Couldn't send survey", e)
        this.$store.commit("StopLoading")
      }
    }
  },
  mounted() {

    if(this.blockUntilVerify){
      // Forzamos que esten verificados antes de seguir
      this.OpenVerification()
    }
  },
};
</script>
